<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="제약사"
            >
              <b-form-input
                v-model="liveDiData.clientName"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="노출 메시지"
            >
              <b-form-input
                v-model="liveDiLiveInfo.liveDiTitle"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="제품명"
            >
              <b-form-input
                v-model="liveDiLiveInfo.brandName"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="청구코드"
            >
              <b-form-input
                v-model="liveDiLiveInfo.liveDiCondDrugCodes"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="질병코드"
            >
              <b-form-input
                v-model="liveDiLiveInfo.liveDiCondDiseaseCodes"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="검사코드"
            >
              <b-form-input
                v-model="liveDiLiveInfo.liveDiCondLabCodes"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          class="mt-1"
        >
          <b-col>
            <label for="liveinfo-livedi-title">노출 구간</label>
          </b-col>
        </b-row>

        <template>
          <b-row
            v-for="(r, idx) in liveDiLiveInfo.liveDiSchedules"
            :id="`livedi-${idx}`"
            :key="idx"
            style="margin-top: 5px"
          >
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <b-form-datepicker
                  v-model="r.startDate"
                  size="sm"
                  placeholder="알림기간(Fr)"
                  readonly
                  disabled
                  @id="`liveinfo-livedi-start-date-${idx}`"
                />
              </b-form-group>
            </b-col>
            <label
              class="text-secondary"
              style="margin-top: 7px;"
            >~</label>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <b-form-datepicker
                  v-model="r.endDate"
                  size="sm"
                  placeholder="알림기간(To))"
                  readonly
                  disabled
                  @id="`liveinfo-livedi-end-date-${idx}`"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >

              <b-input-group
                size="sm"
                class="input-group-merge"
              >
                <b-input-group-prepend>
                  <b-input-group-text
                    class="form-control"
                    style="height: 2.142rem;"
                    readonly
                  >
                    1차
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  v-model="r.firstCount"
                  style="text-align: end;"
                  readonly
                />
                <b-input-group-append>
                  <b-input-group-text
                    class="form-control"
                    style="height: 2.142rem;"
                    readonly
                  >
                    회
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-input-group
                size="sm"
                class="input-group-merge"
              >
                <b-input-group-prepend>
                  <b-input-group-text
                    class="form-control"
                    style="height: 2.142rem;"
                    readonly
                  >
                    2차
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  v-model="r.secondCount"
                  style="text-align: end;"
                  readonly
                />
                <b-input-group-append>
                  <b-input-group-text
                    class="form-control "
                    style="height: 2.142rem;"
                    readonly
                  >
                    회
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </template>
      </div>
    </b-card>

    <b-card no-body>
      <liveDi-click-View
        :live-info-id="liveInfoId"
      />
    </b-card>
  </div>
</template>
<script>

import axios from '@axios'
import {
  BInputGroup, VBTooltip,
  BInputGroupPrepend, BInputGroupAppend, BInputGroupText,
  BFormInput, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onMounted } from "@vue/composition-api"
import { getUserData } from '@/auth/utils'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { useToast } from "vue-toastification/composition"
import liveDiClickView from './LiveDiClickView.vue'

export default {
  components: {
    liveDiClickView,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
    BFormInput,
    BFormDatepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  computed: {

  },
  setup() {
    onMounted(() => {
      fetchLiveDi()
    })
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const liveDiData = ref({})
    const liveDiLiveInfo = ref({})
    const accessCode = ref(null)
    const liveInfoId = ref(0)

    const fetchLiveDi = () => {
      const userData = getUserData()
      accessCode.value = userData.accessCode
      axios.get(`/cl/livedi/${accessCode.value}`)
        .then(rs => {
          const { data } = rs
          liveDiData.value = data
          liveDiLiveInfo.value = data.liveInfo
          liveInfoId.value = data.liveInfo.liveInfoId
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    return {
      liveDiData,
      liveDiLiveInfo,
      liveInfoId,
      fetchLiveDi,
    }
  },
}
</script>

<style lang="scss">
#liveDiPreview .modal-dialog {
  max-width: 1150px;
}
</style>
