var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "제약사" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.liveDiData.clientName,
                            callback: function ($$v) {
                              _vm.$set(_vm.liveDiData, "clientName", $$v)
                            },
                            expression: "liveDiData.clientName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "노출 메시지" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.liveDiLiveInfo.liveDiTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.liveDiLiveInfo, "liveDiTitle", $$v)
                            },
                            expression: "liveDiLiveInfo.liveDiTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "제품명" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.liveDiLiveInfo.brandName,
                            callback: function ($$v) {
                              _vm.$set(_vm.liveDiLiveInfo, "brandName", $$v)
                            },
                            expression: "liveDiLiveInfo.brandName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "청구코드" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.liveDiLiveInfo.liveDiCondDrugCodes,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.liveDiLiveInfo,
                                "liveDiCondDrugCodes",
                                $$v
                              )
                            },
                            expression: "liveDiLiveInfo.liveDiCondDrugCodes",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "질병코드" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.liveDiLiveInfo.liveDiCondDiseaseCodes,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.liveDiLiveInfo,
                                "liveDiCondDiseaseCodes",
                                $$v
                              )
                            },
                            expression: "liveDiLiveInfo.liveDiCondDiseaseCodes",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "검사코드" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.liveDiLiveInfo.liveDiCondLabCodes,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.liveDiLiveInfo,
                                "liveDiCondLabCodes",
                                $$v
                              )
                            },
                            expression: "liveDiLiveInfo.liveDiCondLabCodes",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-1" },
              [
                _c("b-col", [
                  _c("label", { attrs: { for: "liveinfo-livedi-title" } }, [
                    _vm._v("노출 구간"),
                  ]),
                ]),
              ],
              1
            ),
            _vm._l(_vm.liveDiLiveInfo.liveDiSchedules, function (r, idx) {
              return _c(
                "b-row",
                {
                  key: idx,
                  staticStyle: { "margin-top": "5px" },
                  attrs: { id: "livedi-" + idx },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              size: "sm",
                              placeholder: "알림기간(Fr)",
                              readonly: "",
                              disabled: "",
                            },
                            on: {
                              id: function ($event) {
                                "liveinfo-livedi-start-date-" + idx
                              },
                            },
                            model: {
                              value: r.startDate,
                              callback: function ($$v) {
                                _vm.$set(r, "startDate", $$v)
                              },
                              expression: "r.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "text-secondary",
                      staticStyle: { "margin-top": "7px" },
                    },
                    [_vm._v("~")]
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              size: "sm",
                              placeholder: "알림기간(To))",
                              readonly: "",
                              disabled: "",
                            },
                            on: {
                              id: function ($event) {
                                "liveinfo-livedi-end-date-" + idx
                              },
                            },
                            model: {
                              value: r.endDate,
                              callback: function ($$v) {
                                _vm.$set(r, "endDate", $$v)
                              },
                              expression: "r.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "2" } },
                    [
                      _c(
                        "b-input-group",
                        {
                          staticClass: "input-group-merge",
                          attrs: { size: "sm" },
                        },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "b-input-group-text",
                                {
                                  staticClass: "form-control",
                                  staticStyle: { height: "2.142rem" },
                                  attrs: { readonly: "" },
                                },
                                [_vm._v(" 1차 ")]
                              ),
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            staticStyle: { "text-align": "end" },
                            attrs: { readonly: "" },
                            model: {
                              value: r.firstCount,
                              callback: function ($$v) {
                                _vm.$set(r, "firstCount", $$v)
                              },
                              expression: "r.firstCount",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                {
                                  staticClass: "form-control",
                                  staticStyle: { height: "2.142rem" },
                                  attrs: { readonly: "" },
                                },
                                [_vm._v(" 회 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "2" } },
                    [
                      _c(
                        "b-input-group",
                        {
                          staticClass: "input-group-merge",
                          attrs: { size: "sm" },
                        },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "b-input-group-text",
                                {
                                  staticClass: "form-control",
                                  staticStyle: { height: "2.142rem" },
                                  attrs: { readonly: "" },
                                },
                                [_vm._v(" 2차 ")]
                              ),
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            staticStyle: { "text-align": "end" },
                            attrs: { readonly: "" },
                            model: {
                              value: r.secondCount,
                              callback: function ($$v) {
                                _vm.$set(r, "secondCount", $$v)
                              },
                              expression: "r.secondCount",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                {
                                  staticClass: "form-control ",
                                  staticStyle: { height: "2.142rem" },
                                  attrs: { readonly: "" },
                                },
                                [_vm._v(" 회 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("liveDi-click-View", {
            attrs: { "live-info-id": _vm.liveInfoId },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }