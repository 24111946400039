<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <p class="h5 mt-50 mr-75 ml-50">
              조회 기준
            </p>
            <!-- <b-form-radio-group
              v-model="query.countingGroup"
            >
              <b-form-radio :value="2">
                의원
              </b-form-radio>
              <b-form-radio :value="1">
                의사
              </b-form-radio>
            </b-form-radio-group> -->
            <b-form-datepicker
              v-model="query.searchStartDate"
              class="el-def"
              placeholder="게시시작일(Fr)"
              style="width: 250px"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.searchEndDate"
              class="el-def"
              placeholder="게시시작일(To)"
              style="width: 250px"
            />
          </b-col>
          <b-col
            md="4"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card>
      <detail-grid
        :data="liveDiData"
        :search-type="query.countingGroup"
      />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import DetailGrid from './grid/DetailGrid.vue'

export default {
  components: {
    DetailGrid,
  },
  props: {
    liveInfoId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const query = ref({
      countingGroup: 1,
      searchStartDate: dayjs().add(-6, 'day').format('YYYY-MM-DD'),
      searchEndDate: dayjs().format('YYYY-MM-DD'),
      liveInfoId: null,
    })

    watch(() => props.liveInfoId, newVal => {
      query.value.liveInfoId = newVal
    })
    watch(query.value, () => {
      // fetchData()
    })

    const toast = useToast()

    const liveDiData = ref([])

    const fetchData = () => {
      const frDate = dayjs(query.value.searchStartDate)
      const toDate = dayjs(query.value.searchEndDate)

      // // 조회 기간 제한 (해제)
      // if (toDate.diff(frDate, 'day') > 90) {
      //   pushToast('warning', '조회 기간은 최대 90일을 넘을 수 없습니다.')
      //   return
      // }

      // 종료일이 시작일보다 빠를 수 없음
      if (toDate.diff(frDate, 'day') < 0) {
        toast({
          component: ToastificationContent,
          props: {
            title: '종료일이 시작일보다 앞설 수 없습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      axios.post(`/cl/livedi/statistics`, {
        startDate: query.value.searchStartDate,
        endDate: query.value.searchEndDate,
        countingGroup: query.value.countingGroup,
        liveInfoId: query.value.liveInfoId,
      })
        .then(rs => {
          liveDiData.value = rs.data
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: {
              title: `데이터 조회 중 오류가 발생하였습니다.${err}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = 'ID'
      worksheet.getCell('B1').value = '제약사'
      worksheet.getCell('C1').value = '제품명'
      worksheet.getCell('D1').value = '회차'
      worksheet.getCell('E1').value = '기간'
      worksheet.getCell('F1').value = '일자'
      worksheet.getCell('G1').value = '진료과'
      worksheet.getCell('H1').value = '구간조건'
      // worksheet.getCell('I1').value = '구간조건'
      worksheet.getCell('J1').value = '1회 구간 노출수'
      // worksheet.getCell('K1').value = '1회 구간 노출수'
      // worksheet.getCell('L1').value = '1회 구간 노출수'
      worksheet.getCell('M1').value = '2회 구간 노출수'
      // worksheet.getCell('N1').value = '2회 구간 노출수'
      // worksheet.getCell('O1').value = '2회 구간 노출수'
      worksheet.getCell('P1').value = '메세지 클릭수'
      worksheet.getCell('Q1').value = '클릭수 (중복제거)'
      worksheet.getCell('R1').value = '닥터인포 클릭수'
      worksheet.getCell('S1').value = '닥터인포 클릭수 (중복제거)'

      worksheet.mergeCells(`A1:A2`)
      worksheet.mergeCells(`B1:B2`)
      worksheet.mergeCells(`C1:C2`)
      worksheet.mergeCells(`D1:D2`)
      worksheet.mergeCells(`E1:E2`)
      worksheet.mergeCells(`F1:F2`)
      worksheet.mergeCells(`G1:G2`)

      worksheet.mergeCells(`H1:I1`)
      worksheet.getCell('H2').value = '1회 구간'
      worksheet.getCell('I2').value = '2회 구간'
      worksheet.mergeCells(`J1:L1`)
      worksheet.getCell('J2').value = query.value.countingGroup.value === 2 ? '처방의원수' : '처방의사수'
      worksheet.getCell('K2').value = query.value.countingGroup.value === 2 ? '신규처방처' : '신규처방수'
      worksheet.getCell('L2').value = query.value.countingGroup.value === 2 ? '재처방처' : '재처방수'
      worksheet.mergeCells(`M1:O1`)
      worksheet.getCell('M2').value = query.value.countingGroup.value === 2 ? '처방의원수' : '처방의사수'
      worksheet.getCell('N2').value = query.value.countingGroup.value === 2 ? '신규처방처' : '신규처방수'
      worksheet.getCell('O2').value = query.value.countingGroup.value === 2 ? '재처방처' : '재처방수'

      worksheet.mergeCells(`P1:P2`)
      worksheet.mergeCells(`Q1:Q2`)
      worksheet.mergeCells(`R1:R2`)
      worksheet.mergeCells(`S1:S2`)

      worksheet.columns = [
        { key: "liveInfoId", width: 15 },
        { key: "clientName", width: 25 },
        { key: "brandName", width: 25 },
        { key: "seq", width: 10 },
        { key: "periodStr", width: 15 },
        { key: "dateStr", width: 15 },
        { key: "medicalDeptName", width: 15 },
        { key: "firstCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "firstTermGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "firstTermNewCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "firstTermReCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondTermGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondTermNewCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondTermReCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
      ]

      let startRow = 3
      let endRow = startRow
      // eslint-disable-next-line array-callback-return
      liveDiData.value.map(liveDi => {
        const liveDiStartRow = startRow
        const liveDiEndRow = liveDiStartRow + liveDi.rowSpanCount - 1 + (1 * liveDi.liveDiPeriods.length)

        // eslint-disable-next-line array-callback-return
        liveDi.liveDiPeriods.map(period => {
          const periodStartRow = startRow
          let periodEndRow = periodStartRow + period.rowSpanCount - 1

          // eslint-disable-next-line array-callback-return
          period.liveDiDaily.map(day => {
            const dayStartRow = startRow
            const dayEndRow = startRow + day.liveDiDepts.length - 1

            day.liveDiDepts.map(dept => worksheet.addRow({
              liveInfoId: liveDi.liveInfoId,
              clientName: liveDi.clientName,
              brandName: liveDi.brandName,
              seq: period.seq,
              periodStr: period.periodStr,
              dateStr: day.dateStr,
              medicalDeptName: dept.medicalDeptName,
              firstCount: period.firstCount,
              secondCount: period.secondCount,
              firstTermGroupCount: dept.firstTermGroupCount,
              firstTermNewCount: dept.firstTermNewCount,
              firstTermReCount: dept.firstTermReCount,
              secondTermGroupCount: dept.secondTermGroupCount,
              secondTermNewCount: dept.secondTermNewCount,
              secondTermReCount: dept.secondTermReCount,
              totalClickCount: dept.totalClickCount,
              groupClickCount: dept.groupClickCount,
              totalDrInfoClickCount: dept.totalDrInfoClickCount,
              groupDrInfoClickCount: dept.groupDrInfoClickCount,
            }))

            // 일자 병합
            worksheet.mergeCells(`F${dayStartRow}:F${dayEndRow}`)
            startRow = dayEndRow + 1

            endRow = startRow
          })

          // 1회, 2회 구간 병합
          worksheet.mergeCells(`H${periodStartRow}:H${periodEndRow}`)
          worksheet.mergeCells(`I${periodStartRow}:I${periodEndRow}`)

          if (liveDi.liveDiPeriods.length > 0) {
            worksheet.addRow({
              liveInfoId: liveDi.liveInfoId,
              clientName: liveDi.clientName,
              brandName: liveDi.brandName,
              seq: period.seq,
              periodStr: period.periodStr,
              dateStr: '소계',
              medicalDeptName: '',
              firstCount: '',
              secondCount: '',
              firstTermGroupCount: period.subTotal.firstTermGroupCount,
              firstTermNewCount: period.subTotal.firstTermNewCount,
              firstTermReCount: period.subTotal.firstTermReCount,
              secondTermGroupCount: period.subTotal.secondTermGroupCount,
              secondTermNewCount: period.subTotal.secondTermNewCount,
              secondTermReCount: period.subTotal.secondTermReCount,
              totalClickCount: period.subTotal.totalClickCount,
              groupClickCount: period.subTotal.groupClickCount,
              totalDrInfoClickCount: period.subTotal.totalDrInfoClickCount,
              groupDrInfoClickCount: period.subTotal.groupDrInfoClickCount,
            })

            worksheet.mergeCells(`F${endRow}:I${endRow}`)

            periodEndRow += 1
          }

          // 시퀀스(회차), 기간 병합
          worksheet.mergeCells(`D${periodStartRow}:D${periodEndRow}`)
          worksheet.mergeCells(`E${periodStartRow}:E${periodEndRow}`)

          startRow = periodEndRow + 1
          endRow = startRow
        })

        worksheet.mergeCells(`A${liveDiStartRow}:A${liveDiEndRow}`)
        worksheet.mergeCells(`B${liveDiStartRow}:B${liveDiEndRow}`)
        worksheet.mergeCells(`C${liveDiStartRow}:C${liveDiEndRow}`)
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1 || number === 2) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "라이브DI 통계(일별).xlsx")
    }

    return {
      query,
      liveDiData,
      fetchData,
      excelDownload,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdown-menu {
  z-index: 100 !important;
}
</style>
